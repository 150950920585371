import React from "react";
import { useLocation } from "react-router-dom";

export default function Transactions({ data }) {
  const { state } = useLocation();
  return (
    <div className="table-container">
      {state.length > 0 ? (
        <table>
          <thead>
            <tr>
              {Object.keys(state[0]).map((x) => {
                return (
                  <th key={x}>
                    <strong>{x}</strong>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {state.map((d) => {
              return (
                <tr>
                  {Object.values(d).map((x) => {
                    return <td>{x}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="no-rec">No Records Available!!</div>
      )}
    </div>
  );
}
