import React from "react";
import "./index.css";
import { NavLink } from "react-router-dom";
import { AuthData } from "../../App";

export default function NotFound() {
  const { user } = AuthData();
  return (
    <div className="error-head">
      <div className="error-header">
        {user.isAuthenticated
          ? "This Page is under development or URL is wrong"
          : "Your aren't Logged in !!"}
      </div>
      {user.isAuthenticated ? (
        <p>Please go to Home Page</p>
      ) : (
        <p>Please go to login Page</p>
      )}
      {user.isAuthenticated ? (
        <NavLink to="/home">Home</NavLink>
      ) : (
        <NavLink to="/">Login</NavLink>
      )}
    </div>
  );
}
