import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { certified, copyright_text, header } from "../utils/constants";
import { AuthData } from "../App";
import Profile from "./profile/Profile";
import MenuBar from "./menu-bar";

export default function Header() {
  const navigate = useNavigate();
  const logo = require("./../assests/logo.png");
  const { user } = AuthData();
  return (
    <div className="page-container">
      <div className="content-wrap">
        <div className="header">
          <div className="header-left">
            <img
              alt="VEROSAI-icon"
              src={logo}
              onClick={() => navigate("/home")}
            />
            <div>{header}</div>
          </div>
          <div className="header-right">
            {/* <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
              <div style={{ marginRight: "10px" }}>{certified}</div>
              <img
                alt="SAP-Icon"
                src="https://sainav.com/wp-content/uploads/2024/01/csm_sap-partner-logo_4e3c3d048f.png"
              />
            </div> */}
            {user.isAuthenticated && <Profile />}
          </div>
        </div>
        {user.isAuthenticated && <MenuBar />}
        <Outlet />
      </div>
      <div className="footer">
        <p>{copyright_text}</p>
      </div>
    </div>
  );
}
