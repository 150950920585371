import React from "react";

function Home() {
    return <div style={{display: "flex", alignItems:"center", justifyContent:"center"}}>
        <img 
            src="https://img1.wsimg.com/isteam/ip/8783b73c-f9a6-4213-b34c-bc920e6cf5ce/Moving%20Migration.png/:/rs=w:1600,h:1600,cg:true,m/cr=w:1600,h:1600" 
            width="724px" 
            height="724px"
        />
    </div>
}

export default Home;