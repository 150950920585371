import React, { useEffect, useState } from "react";
import { API_URL, tenant_fields } from "../../utils/constants";
import Overlay from "../overlay/Overlay";
import { useNavigate } from "react-router-dom";
import TenantTable from "./TenantTable";
import Loader from "../loader";

export default function TenantView() {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [isLoading, setLoading] = useState(false);
  //   const [showPop, setShowPop] = useState(false);
  //   const [isLoading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  // console.log(state);

  const getData = () => {
    setLoading(true);
    fetch(API_URL + "admin1_view/")
      .then((data) => {
        return data.json();
      })
      .then((post) => {
        post.forEach((ele) => {
          if (ele.status === 0) ele.status = "false";
          else ele.status = "true";
        });
        setState(post);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  
  useEffect(() => {
    getData();
  }, []);

  const removeTenant = (id) => {
    setMsg("Deleting....");
    fetch(API_URL + "admin1_delete/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ tenant_id: id }),
    })
      .then((data) => {
        let temp = state.filter((d) => d.tenant_id !== id);
        setState(temp);
        setMsg("Deleted");
      })
      .catch((err) => {
        console.log(err);
        setMsg("Unable to delete try again!!");
      });
  };

  const addButton = () => {
    return (
      <div className="prod-actions">
        <button onClick={() => navigate("/tenant")}>Add new record</button>
      </div>
    );
  };


  if (state.length === 0) {
    return (
      <>
        {addButton()}
        <h2 style={{ textAlign: "center" }}>No Records Available!!</h2>
        {isLoading && (
          // <Overlay
          //   closeMethod={() => setLoading(false)}
          //   isLoader={true}
          // ></Overlay>
          <div style={{position: "fixed", height:"100vh", left: "50%", top: "50%"}}>
            <Loader size="50px" isFullPage />
          </div>
        )}
      </>
    );
  }


  return (
    <div className="product-con">
      {addButton()}
      {msg && (
        <div
          className="msg"
          style={{ margin: "auto", marginTop: "20px", fontWeight: "bold" }}
        >
          <div className="fail">{msg}</div>
          <div>
            <button onClick={() => setMsg("")}>X</button>
          </div>
        </div>
      )}
      <TenantTable
        state={state}
        remove={removeTenant}
        edit="/tenant-edit"
        fields={tenant_fields}
      />
      {/* {showPop && (
        <Overlay closeMethod={() => setShowPop(false)} isLoader={false}>
          <p>Want to Continue ?</p>
          <div className="pop-btn">
            <button onClick={(e) => handlePush(e)}>YES</button>
            <button onClick={() => setShowPop(false)}>NO</button>
          </div>
        </Overlay>
      )} */}
      {isLoading && (
        // <Overlay
        //   closeMethod={() => setLoading(false)}
        //   isLoader={true}
        // ></Overlay>
        <Loader size="80px"/>
      )}
    </div>
  );
}
