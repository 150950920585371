import React from "react";
import { useLocation } from "react-router-dom";
import TenantView from "./TenantView";
import TenantUserView from "./tenant_user/TenantUserView";
import TenantOpView from "./tenant_op/TenantOpView";
import NotFound from "../notfound/NotFound";

export default function TargetConfiguration() {

  const location = useLocation();

  const queryParams = location.search.split("=");
  const componentId = queryParams[1];

  const renderSwitch = (id) => {
    switch(id){
      case "/target-configuration/tenant":
          return <TenantView />;
      case "/target-configuration/tenant-user":
          return <TenantUserView />;
      case "/target-configuration/tenant-operation":
          return <TenantOpView />;
      default:
          return <NotFound />
    }
  }

  return (
    <div>
      {renderSwitch(componentId)}
    </div>
  );
}
