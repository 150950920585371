import React, { useEffect, useState } from "react";
import { API_URL, tenant_user_fields } from "../../../utils/constants";
import Overlay from "../../overlay/Overlay";
import { useNavigate } from "react-router-dom";
import TenantTable from "../TenantTable";

export default function TenantUserView() {
  const navigate = useNavigate();
  const [state, setState] = useState([]);
  const [isLoading, setLoading] = useState(false);
  //   const [showPop, setShowPop] = useState(false);
  //   const [isLoading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  // console.log(state);

  const getData = () => {
    setLoading(true);
    fetch(API_URL + "admin2_view/")
      .then((data) => {
        return data.json();
      })
      .then((post) => {
        setState(post);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const removeTenant = (tid, id) => {
    setMsg("Deleting....");
    fetch(API_URL + "admin2_delete/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ tenant_id: tid, id }),
    })
      .then((data) => {
        let temp = state.filter((d) => d.tenant_id !== tid);
        setState(temp);
        setMsg("Deleted");
      })
      .catch((err) => {
        console.log(err);
        setMsg("Unable to delete try again!!");
      });
  };

  const addButton = () => {
    return (
      <div className="prod-actions">
        <button onClick={() => navigate("/tenant-user")}>Add new record</button>
      </div>
    );
  };

  if (state.length === 0) {
    return (
      <>
        {addButton()}
        <h2 style={{ textAlign: "center" }}>No Records Available!!</h2>
        {isLoading && (
          <Overlay
            closeMethod={() => setLoading(false)}
            isLoader={true}
          ></Overlay>
        )}
      </>
    );
  }
  return (
    <div className="product-con">
      {addButton()}
      {msg && (
        <div
          className="msg"
          style={{ margin: "auto", marginTop: "20px", fontWeight: "bold" }}
        >
          <div className="fail">{msg}</div>
          <div>
            <button onClick={() => setMsg("")}>X</button>
          </div>
        </div>
      )}
      <TenantTable
        state={state}
        remove={removeTenant}
        edit="/tenant-user-edit"
        fields={tenant_user_fields}
      />
      {/* {showPop && (
        <Overlay closeMethod={() => setShowPop(false)} isLoader={false}>
          <p>Want to Continue ?</p>
          <div className="pop-btn">
            <button onClick={(e) => handlePush(e)}>YES</button>
            <button onClick={() => setShowPop(false)}>NO</button>
          </div>
        </Overlay>
      )} */}
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
