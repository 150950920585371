import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "../overlay/Overlay";
import { Call } from "../../utils/api";

export default function BPcustomer() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [err, setErr] = useState("");
  const Schema = Yup.object().shape({
    Customer: Yup.string().required("Required"),
    Business_Partner: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          Customer: "",
          Business_Partner: "",
        }}
        validationSchema={Schema}
        onSubmit={async (values) => {
          console.log(values);
          setLoading(true);
          try {
            let data = await Call(
              "bpcustomer_view/",
              "POST",
              JSON.stringify(values)
            );
            setLoading(false);
            navigate("/bp_customer/view", {
              state: ["bp_customer", data],
            });
          } catch (res) {
            console.log(res);
            setErr("Error occurred");
            setLoading(false);
          }
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Customer :</label>
            <Field name="Customer" />
            {errors.Customer && touched.Customer ? (
              <div className="error">{errors.Customer}</div>
            ) : null}
            <label>Business Partner :</label>
            <Field name="Business_Partner" />
            {errors.Business_Partner && touched.Business_Partner ? (
              <div className="error">{errors.Business_Partner}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
