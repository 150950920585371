import React, {useEffect} from "react";
import { useLocation } from "react-router-dom";
import Bank from "./Bank";
import Costcenter from "./Costcenter";
import BPsupplier from "./BPsupplier";
import BPcustomer from "./BPcustomer";
import Logs from "./Logs/Logs";
import ProductSelection from "./ProductSelection";
import CustomerSalesSelection from "./CustomerSalesSelection";
import WorkCenter from "./WorkCenter";
import Routing from "./Routing";
import Characteristic from "./characteristic/Characteristic";
import Classification from "./Classification";
import NotFound from "../notfound/NotFound";


export default function MigrationCockpit() {
  const location = useLocation();

  useEffect(()=>{
    console.log("location updated : ", location);
  }, [location]);

  const queryParams = location.search.split("=");
  const componentId = queryParams[1];

  const renderSwitch = (id) => {
    switch(id){
      case "/migration-cockpit/bank":
        return <Bank />;
      case "/migration-cockpit/cost-center":
        return <Costcenter />;
      case "/migration-cockpit/bp-supplier":
        return <BPsupplier />;
      case "/migration-cockpit/bp-customer":
        return <BPcustomer />;
      case "/migration-cockpit":
      case "/migration-cockpit/bp-product":
        return <ProductSelection />;
      case "/migration-cockpit/bp-logs":
        return <Logs />;
      case "/migration-cockpit/routing":
        return <Routing />;
      case "/migration-cockpit/work-center":
        return <WorkCenter />;
      case "/migration-cockpit/customer-sales":
        return <CustomerSalesSelection />;
      case "/migration-cockpit/characteristic":
        return <Characteristic />;
      case "/migration-cockpit/classification":
        return <Classification  />
      default:
        return <NotFound />;
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: "center" }}>
      {renderSwitch(componentId)}
    </div>
  );
}
