import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Overlay from "../overlay/Overlay";
import { API_URL } from "../../utils/constants";

export default function Routing() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const Schema = Yup.object().shape({
    ProductionRoutingGroup: Yup.string().required("Required"),
    // Account_Group: Yup.string().required("Required"),
  });
  return (
    <div>
      <Formik
        initialValues={{
          ProductionRoutingGroup: "",
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          console.log(values);
          setLoading(true);
          fetch(API_URL + "productionroutinglist/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              navigate("/product/view", {
                state: ["ProductionRoutingGroup", data],
              });
            })
            .catch(function (res) {
              console.log(res);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form className="main">
            <label>Production Routing Group ID :</label>
            <Field name="ProductionRoutingGroup" />
            {errors.ProductionRoutingGroup && touched.ProductionRoutingGroup ? (
              <div className="error">{errors.ProductionRoutingGroup}</div>
            ) : null}
            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
