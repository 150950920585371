import React, { useState, useRef, act } from 'react';
import { useNavigate } from "react-router-dom";
import { AuthData } from '../../App';
import cx from "classnames";
import './menu-bar.css';


const RenderSubMenu = ({ submenu, onSubmenuSelect }) => {
    return (<div className="submenu">
        {submenu.map(category => {
            return <div>
                <h5>{category.name}</h5>
                <ol>
                    {category.subMenu.map((cat) => {
                        return <li className="submenu-item">
                            <a onClick={() => onSubmenuSelect(cat)}>{cat.name}</a>
                        </li>
                    })}
                </ol>
            </div>
        })}
    </div>)
}


const MenuBar = () => {

    const navigate = useNavigate();
    const secondaryMenuContainerRef = useRef(null);
    const { user } = AuthData();

    const menuItems = [
        { name: "Home", path: "/home" },
        {
            name: 'Migration Cockpit',
            path: "/migration-cockpit",
            subMenu: [
                {
                    name: "Finance",
                    subMenu: [
                        { path: "/migration-cockpit/bank", name: "Bank", enabled: true,  },
                        { path: "/migration-cockpit/profit-center", name: "Profit Center", enabled: true },
                        { path: "/migration-cockpit/cost-center", name: "Cost Center", enabled: true },
                        { path: "/migration-cockpit/g-l-account", name: "G/L Account", enabled: true },
                        { path: "/migration-cockpit/asset", name: "Asset", enabled: true },
                        { path: "/migration-cockpit/cost-element", name: "Cost Element", enabled: true },
                        { path: "/migration-cockpit/activity-type", name: "Activity Type", enabled: true },
                    ],
                },
                {
                    name: "General Master Data",
                    subMenu: [
                        { path: "/migration-cockpit/bp-supplier", name: "BP-Supplier" },
                        { path: "/migration-cockpit/bp-customer", name: "BP-Customer" },
                        { path: "/migration-cockpit/bp-employee", name: "BP-Employee" },
                        { path: "/migration-cockpit/bp-product", name: "Product" },
                        { path: "/migration-cockpit/bp-bom", name: "BOM" },
                        { path: "/migration-cockpit/bp-change-number", name: "Change Number" },
                        { path: "/migration-cockpit/bp-logs", name: "Logs" },
                    ],
                },
                {
                    name: "Purchasing",
                    subMenu: [
                        { path: "/migration-cockpit/purchasing-info-record", name: "Purchasing Info Record" },
                        { path: "/migration-cockpit/source-list", name: "Source List" }],
                },
                {
                    name: "Manufacturing",
                    subMenu: [
                        { path: "/migration-cockpit/routing", name: "Routing" },
                        { path: "/migration-cockpit/work-center", name: "Work center" },
                        { path: "/migration-cockpit/customer-sales", name: "Customer Sales" }
                    ],
                },
                {
                    name: "Quality Management Master",
                    subMenu: [
                        { path: "/migration-cockpit/inspection-pi", name: "Inspection PI" },
                        { path: "/migration-cockpit/mic", name: "MIC" },
                        { path: "/migration-cockpit/qa-insp-pro", name: "QA Insp. Pro" },
                        { path: "/migration-cockpit/characteristic", name: "Characteristic" },
                        { path: "/migration-cockpit/classification", name: "Classification" },
                    ],
                },
            ]
        },
        {
            name: 'Data Configuration',
            path: '/data-configuration',
            subMenu: [
                {
                    name: "Data Configuration",
                    subMenu: [
                        { path: "/data-configuration/configuration-for-product", name: "Product Configuration", enabled: true },
                        { path: "/data-configuration/configuration-for-customer", name: "Customer Configuration", enabled: true },
                        { path: "/data-configuration/configuration-for-vendor", name: "Vendor Configuration", enabled: true }
                    ],
                },

            ],
        },
        { name: "Load Data", path: "/load-data" },
        { name: "Data Lake", path: "/odataView" },
        {
            name: 'Target Configuration',
            path: '/target-configuration',
            subMenu: [
                {
                    name: "Target Configuration",
                    subMenu: [
                        { path: "/target-configuration/tenant", name: "Tenant", enabled: true },
                        { path: "/target-configuration/tenant-user", name: "Tenant User", enabled: true },
                        { path: "/target-configuration/tenant-operation", name: "Tenant Operation", enabled: true }
                    ],
                },

            ],
        },
        { name: "Users", path: "/user-details" }
        // Add more items as needed
    ];

    const [activeItem, setActiveItem] = useState({ path: "/home", name: "Home", enabled: true });

    const handleClick = (selectedItem) => {
        const newPath = selectedItem.path.split("/");
        console.log(selectedItem);
        setActiveItem(selectedItem);
        navigate(`/${newPath[1]}?id=${selectedItem.path}`);
    };

    return (
        <>
            <div className="menu-container">
                <ul className="menu-list">
                    {menuItems.map((item) => (
                        <li
                            key={item.path}
                            className={cx("menu-item", activeItem.path.includes(item.path) && "active")}
                        >
                            <>
                                <a className="menu-link" onClick={() => handleClick(item)}>
                                    {item.name}
                                </a>
                                {item.subMenu && item.subMenu.length > 0 && <div className="submenu-container">
                                    <RenderSubMenu submenu={item.subMenu} onSubmenuSelect={handleClick} />
                                </div>}
                            </>
                        </li>
                    ))}
                </ul>
            </div>
            {activeItem.name !== "Home" &&  <p className="function-title">{activeItem.name}</p>}
        </>
    );
};

export default MenuBar;
