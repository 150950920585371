import React from 'react';
import PropTypes from 'prop-types';
import './Loader.css'; // Assuming you'll create a separate CSS file for styling

const Loader = ({ size, className, isFullPage}) => {
  const loaderStyle = {
    width: size,
    height: size,
  };

  return (
    <div className={`loader-container ${isFullPage ? 'full-size' : ''}`}>
      <div className={`loader ${className}`} style={loaderStyle}></div>
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.string, // e.g., '50px', '100px'
  className: PropTypes.string,
  isFullPage: PropTypes.bool,
};

Loader.defaultProps = {
  size: '50px', // Default size if not provided
  className: '',
  isFullPage: false,

};

export default Loader;
