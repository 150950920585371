import React, { useEffect, useState } from "react";
import { API_URL } from "../../../utils/constants";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

export default function TenantOpForm() {
  const [err, setErr] = useState("");
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const Schema = Yup.object().shape({
    tenant: Yup.string().required("Required"),
    odata_type: Yup.string().required("Required"),
    operation: Yup.string().required("Required"),
    url: Yup.string().required("Required"),
    object_name: Yup.string().required("Required"),
  });
  useEffect(() => {
    fetch(API_URL + "t_list/")
      .then((data) => {
        return data.json();
      })
      .then((post) => {
        setItems(post);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Formik
        initialValues={{
          tenant: "",
          odata_type: "",
          operation: "",
          url: "",
          object_name: "",
        }}
        validationSchema={Schema}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          // console.log(values);
          fetch(API_URL + "admin3_insert/", {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(values),
          })
            .then(async (res) => {
              let data = await res.json();
              console.log(data);
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 3000);
              setErr("");
              resetForm();
              setSubmitting(false);
              // navigate("/product/view", {
              //   state: ["Product", data],
              // });
            })
            .catch(function (res) {
              resetForm();
              console.log(res);
              setSubmitting(false);
              setErr("Error occurred");
            });
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="main">
            <label>Tenant Id :</label>
            <Field as="select" name="tenant">
              <option value="">select a value ....</option>
              {items.length > 0 ? (
                items.map((x) => {
                  return (
                    <option key={x} value={x}>
                      {x}
                    </option>
                  );
                })
              ) : (
                <option style={{ fontWeight: "bold" }}>Loading ....</option>
              )}
            </Field>
            {errors.tenant && touched.tenant ? (
              <div className="error">{errors.tenant}</div>
            ) : null}
            <label>Object Type :</label>
            <Field name="odata_type" type="odata_type" />
            {errors.odata_type && touched.odata_type ? (
              <div className="error">{errors.odata_type}</div>
            ) : null}
            <label>Operation :</label>
            <Field as="select" name="operation">
              <option value="">select a value ....</option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="PATCH">PATCH</option>
              <option value="DELETE">DELETE</option>
            </Field>
            {errors.operation && touched.operation ? (
              <div className="error">{errors.operation}</div>
            ) : null}
            <label>URL :</label>
            <Field name="url" />
            {errors.url && touched.url ? (
              <div className="error">{errors.url}</div>
            ) : null}
            <label>Object Name :</label>
            <Field name="object_name" />
            {errors.object_name && touched.object_name ? (
              <div className="error">{errors.object_name}</div>
            ) : null}
            {err && <p className="error">{err}</p>}
            {success && (
              <p style={{ color: "lightgreen" }}>Added successfully!!</p>
            )}
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting...." : "Submit"}
            </button>
          </Form>
        )}
      </Formik>
      <div className="card" onClick={() => navigate("/tenant-op-view")}>
        <p>Display Tenants </p>
      </div>
    </div>
  );
}
