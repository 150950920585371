import React, { useEffect, useState } from "react";
import { API_URL, OData_keys } from "../../utils/constants";
import Overlay from "../overlay/Overlay";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import Logs from "../migration-cockpit/Logs/Logs";

export default function OdataView() {
  const [state, setState] = useState([]);
  const [showPop, setShowPop] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [msg, setMsg] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  // console.log(state);

  useEffect(() => {
    fetch(API_URL + "odatadisplay/")
      .then((data) => {
        return data.json();
      })
      .then((post) => {
        post.forEach((x) => {
          delete x.id;
          x.Filters = moment(new Date(new Date())).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        });
        setState(post);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSelection = (item) => {
    item = JSON.stringify(item);
    if (selectedProducts.includes(item)) {
      setSelectedProducts(selectedProducts.filter((x) => x !== item));
    } else {
      setSelectedProducts([...selectedProducts, item]);
    }
  };

  const selectAll = (e) => {
    setSelectedProducts([]);
    if (e.target.checked) {
      for (let item of state) {
        selectedProducts.push(
          JSON.stringify({ API: item["url"], run_at: item["Filters"] })
        );
      }
      setSelectedProducts([...selectedProducts]);
    }
  };
  const handleDate = (api, date) => {
    // let cur = state.filter((item) => item.API === api);
    // let rem = state.filter((item) => item.API !== api);
    let cur = state;
    cur.forEach((x) => {
      if (x.url === api) x.Filters = moment(date).format("YYYY-MM-DD HH:mm:ss");
    });
    setState([...cur]);
    setSelectedProducts([]);
  };
  const handlePush = (e) => {
    e.stopPropagation();
    let values = [...selectedProducts];
    setSelectedProducts([]);
    values = values.map((x) => JSON.parse(x));
    setLoading(true);
    // console.log(values);
    fetch(API_URL + "csv/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(values),
    })
      .then(async (res) => {
        let data = await res.json();
        // console.log(data);
        setMsg(data);
        setShowPop(false);
        setLoading(false);
      })
      .catch(function (res) {
        // console.log(res);
        setMsg(res);
        setShowPop(false);
        setLoading(false);
      });
  };
  if (state.length === 0) {
    return <Overlay closeMethod={() => {}} isLoader={true}></Overlay>;
  }
  return (
    <div className="product-con">
      <div className="prod-actions">
        <button onClick={() => setShowPop(true)}>Download as CSV</button>
        <button onClick={() => setShowLogs(!showLogs)}>
          {!showLogs ? "Logs" : "Data Lake"}
        </button>
      </div>
      {msg.length > 0 && (
        <div className="msg">
          <div>
            {msg.map((m) => {
              return (
                <div key={m} className={m.status === 200 ? "success" : "fail"}>
                  {m.message}
                </div>
              );
            })}
          </div>
          <div>
            <button onClick={() => setMsg([])}>X</button>
          </div>
        </div>
      )}
      {showLogs ? (
        <Logs link="datalake/" />
      ) : (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectedProducts.length === state.length}
                    onChange={selectAll}
                  ></input>
                </th>
                {OData_keys.map((x) => {
                  return (
                    <th key={x}>
                      <strong>{x}</strong>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {state.map((d) => {
                return (
                  <tr key={d["url"]}>
                    <td>
                      <input
                        type="checkbox"
                        checked={
                          selectedProducts.filter(
                            (x) =>
                              x ===
                              JSON.stringify({
                                API: d["url"],
                                run_at: d["Filters"],
                              })
                          ).length > 0
                        }
                        onChange={() => {
                          handleSelection({
                            API: d["url"],
                            run_at: d["Filters"],
                          });
                        }}
                      ></input>
                    </td>
                    {Object.values(d).map((x) => {
                      return x === d.Filters ? (
                        <td key={x} className="date-con">
                          <DatePicker
                            showTimeSelect
                            minDate={new Date()}
                            selected={new Date(x)}
                            dateFormat="MMMM, dd YYYY HH:mm"
                            onChange={(date) => handleDate(d.url, date)}
                            timeFormat="HH:mm"
                            timeIntervals={5}
                          />
                        </td>
                      ) : (
                        <td key={x}>{x}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {showPop && (
        <Overlay closeMethod={() => setShowPop(false)} isLoader={false}>
          <p>Want to Continue ?</p>
          <div className="pop-btn">
            <button onClick={(e) => handlePush(e)}>YES</button>
            <button onClick={() => setShowPop(false)}>NO</button>
          </div>
        </Overlay>
      )}
      {isLoading && <Overlay closeMethod={() => {}} isLoader={true}></Overlay>}
    </div>
  );
}
