import React, { useEffect, useState } from "react";
import Overlay from "../../overlay/Overlay";
import { API_URL } from "../../../utils/constants";
import "./Logs.css";


export default function Logs({ link }) {

  const [data, setData] = useState([]);

  if (!link) link = "list/";

  useEffect(() => {
    fetch(API_URL + link)
      .then((data) => {
        return data.json();
      })
      .then((post) => {
        setData(post);
      })
      .catch((err) => { });
  }, []);


  return (
    <div className="table-container">
      {data.length > 0 ? (
        <table>
          <thead>
            <tr>
              {Object.keys(data[0]).map((x) => {
                return (
                  <th key={x}>
                    <strong>{x}</strong>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              return (
                <tr>
                  {Object.values(d).map((x) => {
                    return <td>{x}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Overlay closeMethod={() => { }} isLoader={true}></Overlay>
      )}
    </div>
  );
}
