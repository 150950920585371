import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function TenantTable({ state, remove, edit, fields }) {
  const navigate = useNavigate();
  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>
            {fields.map((x) => {
              return (
                <th key={x}>
                  <strong>{x}</strong>
                </th>
              );
            })}
            <th>
              <strong>Actions</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {state.map((d) => {
            return (
              <tr key={d.tenant_id || d.tenant}>
                {Object.values(d)
                  .filter((x) => x !== d.id)
                  .map((x) => {
                    return <td key={x}>{x}</td>;
                  })}
                <td
                  style={{
                    fontSize: "large",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{ color: "#d62931", cursor: "pointer" }}
                    onClick={() => remove(d.tenant_id || d.tenant, d?.id)}
                  >
                    <AiOutlineDelete />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(edit, { state: d })}
                  >
                    <FaEdit />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
