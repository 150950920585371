import React, { useState, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import classes from "./selectplan.module.css";
import { API_URL } from "../../../utils/constants";

export default function SelectPlant() {
  const [types, setTypes] = useState([]);
  useEffect(() => {
    fetch(API_URL + "product-type-view/", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then(async (res) => {
        let data = await res.json();
        console.log(data);
        setTypes(data);
      })
      .catch(function (res) {
        console.log(res);
      });
  }, []);

  return (
    <div className={classes["data-container"]}>
      {types.length > 0 ? (
        <Formik
          initialValues={{
            checked: types.filter((x) => x.status).map((x) => x.ProductType),
          }}
          onSubmit={(values, { setSubmitting }) => {
            let payload = [];
            values.checked.forEach((element) => {
              payload.push({ ProductType: element, status: "True" });
            });
            types.forEach((ele) => {
              if (!values.checked.includes(ele.ProductType)) {
                payload.push({
                  ProductType: ele.ProductType,
                  status: "False",
                });
              }
            });
            fetch(API_URL + "product-type/", {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              method: "POST",
              body: JSON.stringify(payload),
            })
              .then(async (res) => {
                await res.json();
                setSubmitting(false);
              })
              .catch(function (res) {
                console.log(res);
                setSubmitting(false);
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={`${classes["checkbox-grp"]} table-container`}>
                <table>
                  <thead>
                    <tr className={classes.tableHeaders}>
                      <th>Internal Number</th>
                      <th>Product Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {types.map((ele) => {
                      return (
                        <tr key={ele.id}>
                          <td>
                            <Field
                              type="checkbox"
                              name="checked"
                              value={ele.ProductType}
                            />
                          </td>
                          <td>
                            <label>{ele.ProductType}</label>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <button
                type="submit"
                className={classes["select-button"]}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting...." : "Submit"}
              </button>
            </Form>
          )}
        </Formik>
      ) : (
        <div className={classes.load}>Loading....</div>
      )}
    </div>
  );
}
