import React from "react";
import { useLocation } from "react-router-dom";
import NotFound from "../notfound/NotFound";
import SelectPlant from "./select-plant/SelectPlant";
import CustomerConfig from "./customer-config/CustomerConfig";

export default function DataConfiguration() {

  const location = useLocation();

  const queryParams = location.search.split("=");
  const componentId = queryParams[1];

  const renderSwitch = (id) => {
    switch(id){
      case "/data-configuration/configuration-for-product":
          return <SelectPlant />;
      case "/data-configuration/configuration-for-customer":
          return <CustomerConfig />;
      default:
          return <NotFound />
    }
  }
  
  return (
    <div>
      {renderSwitch(componentId)}
    </div>
  );
}
